import { useEffect, useState, memo } from 'react';
import {
  FacebookShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinShareButton,
  EmailShareButton,
  EmailIcon,
  LinkedinIcon
} from "react-share";
import Icon from '@material-ui/core/Icon';

import Service from "../services/service";

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ShareIcon from '@material-ui/icons/Share';

const ShareButtons = (props) => {

    const [copyText, setCopyText] = useState(null);
    const [showCopy, setShowCopy] = useState(props?.showCopy);

    const shareUrl = props.url || 'https://odete.com.br';
    const title = props.title || 'Odete - Diaristas';

    const share = async (data) => {

      event.preventDefault();

      var obj = {
          title: data.title,
          text: data.text,
          url: data.url
      } 

      let imageUrl = props.imageUrl || null;

    if(imageUrl){
      const image = await fetch(imageUrl);
      const blob = await image.blob();
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
      obj.files = [file] 
    }


      if(navigator.share){
        navigator.share(obj);
      }else{
        alert('O seu dispositivo não tem suporte a compartilhando, copie o link e envie para a pessoa.');
      }
    }

    const copyClipboard = async (text) => {
      await Service.writeText(text);
      setCopyText(text);
    }

    return (
      <div className="text-center">
        <h2 className="text-center h6">{props.header ? props.header : 'Compartilhe agora!'}</h2>
        <div className="text-center d-flex align-items-start justify-content-center">
          
          <div className="me-2">
            <FacebookShareButton url={shareUrl} quote={title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>

          <div className="me-2">
            <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>

          <div className="me-2">
            <LinkedinShareButton url={shareUrl} title={title} summary={title} source={'Odete - A Maior plataforma para diaristas'}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>

          <div className="me-2">
            <EmailShareButton url={shareUrl} subject={title} body={''} separator={''}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>

          <button type="button" onClick={() => share({
            title,
            url: shareUrl,
            text: title
          })} className="btn p-0">
            <ShareIcon titleAccess="Compartilhar" />
          </button>

        </div>
        {showCopy && <div>
          <div className="text-center py-1">
            <small>ou</small>
          </div>
          <div>
            <button type="button" onClick={() => copyClipboard(props.url)} className={`btn btn-block ${copyText ? 'btn-success' : 'btn-outline-secondary'}`}>
              {!copyText && <><CheckCircleOutlineIcon /> <small className="d-block">copiar link</small></>}
              {copyText && <><CheckCircleIcon /> <small className="d-block">copiado</small></>}
            </button>
          </div>
        </div>}
      </div>
    );
}

export default memo(ShareButtons);