import {HYDRATE} from 'next-redux-wrapper';
import { AUTHENTICATE, DEAUTHENTICATE, GETUSER, FINDMAKER, PUBLISH } from '../types';

const initialState = {
  token: null,
  user:null,
  colaboradores: null,
  disparos: []
};

const reducer = (state = initialState, action) => {

  switch(action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload
      };
    case GETUSER:
      return { 
        ...state,
        user: action.payload,
        colaboradores: state.colaboradores
      };
    case PUBLISH:
      return { 
        ...state,
        disparos: action.payload
      };
    case FINDMAKER:
      return { 
        ...state,
        colaboradores: action.payload,
        disparos: action.payload.data
      };
    case AUTHENTICATE:
        return {
          ...state,
          token: action.payload
        }
    case DEAUTHENTICATE:
      return {
        ...state,
        token: null,
        user: null
      };
    default:
      return {...state};
    }
};

export default reducer;