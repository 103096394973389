import {useEffect} from 'react';
import MenuHeader from './menu';
import Footer from './footer';
import { initGA, logPageView } from '../utils/analytics'
//import ReactInspectlet from '../utils/inspectlet';

const Layout = (props) => {

	useEffect(() => {
		if (!window.GA_INITIALIZED) {
		  initGA()
		  window.GA_INITIALIZED = true
		}
		logPageView()
		//ReactInspectlet.initialize(1258720968);
	}, []);
    
	return (
		<>
			<MenuHeader />
			{props.children}
			<Footer />
		</>
	);
}

export default Layout;