import { useEffect, useState, memo, useRef } from 'react';
import Service from "../services/service";
import SignInScreen from "./signinscreen";
import PubSub from 'pubsub-js'
import firebase from "../src/firebase/fire.js";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Link from "next/link";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { connect } from 'react-redux';
import actions from '../redux/actions';
import initialize from '../utils/initialize';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { logEvent } from '../utils/analytics'

const ModalLogin = (props) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentUser, setCurrentUser] = useState(false);
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(false);
  const [userOauth, setUserOauth] = useState(false);

  var subscribeModal = null;

  useEffect(() => {

    subscribeModal = PubSub.subscribe('openModal', (type, model) => {
      setOpen(true)
      setModel(model);
    });

    let isMounted = true;  
    let auth = firebase.auth().onAuthStateChanged(async (data) => {

      if(isMounted){

        let user = data?.toJSON();
        if(user && user !== null){

          if(!user.email){
            alert('voce precisa se logar com uma conta que tenha e-mail');
          }else {

            props.authenticateByUID(user.uid, user).then(resp => {

              if(!resp.success){
                props.deauthenticate().then(() => {
                  firebase.auth().signOut();
                  handleClose()
                });
              }else{

                setCurrentUser(user);
                setUserOauth(true);
              }
              
              setUserOauth(false);
            });
          }
        }else{
          setCurrentUser(false);
        }
      }

    })

    return () => {
      auth = null;
      isMounted = false;
      PubSub.unsubscribe(subscribeModal);
      subscribeModal = null;
    }  
  }, [])

  const handleNotLogin = () => {
    logEvent('LOGIN', 'NOT LOGIN');
    handleClose();
  }

  const finalizarCadastro = async () => {

    console.log('finalizarCadastro => currentUser => ', currentUser);

    // Service.saveSocialUser(currentUser.uid, currentUser).then(resp => {
    //   console.log('finalizarCadastro => resp => ', resp);
    //   authenticateByUID(currentUser.uid, currentUser)
      PubSub.publish('openModal');
    // });
  }

  const handleClose = () => {
    setOpen(false)
    setModel(false);
    PubSub.unsubscribe(subscribeModal);
  };

  return (
      <>
        <div id="firebaseui-auth-container"></div>
        <Dialog open={open} fullScreen={fullScreen}>
            <DialogTitle>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0 barlow fw-bold">VOCÊ É CONTRATANTE?</p>
                  </div>
                  <IconButton aria-label="close" onClick={handleNotLogin}>
                    <CloseIcon titleAccess="fechar modal" />
                  </IconButton>
                </div>
            </DialogTitle>
            <DialogContent dividers={true} className="text-center">
                  
                  {!currentUser &&
                    <div>
                      <div>
                        {model && 
                          <p className="lead fw-bold barlow m-0">Visualizar o perfil da {model?.nome}</p>}
                          <p className="lead text-muted">Se você é <strong>CONTRATANTE</strong> e quer usar esta funcionalidade precisamos que você faça seu login na plataforma.</p>
                          <p>Escolha uma forma de login abaixo, é rapidinho ;-)</p>
                      </div>
                      <SignInScreen />
                      <hr />
                      <Link href="/register" className="btn btn-danger btn-lg rounded-pill barlow fw-bold px-4">SOU DIARISTA</Link>
                    </div>
                  }
                  {currentUser &&
                    <div>
                      <div>Que shoooow {currentUser.displayName}!</div>
                      <div>Agora você já poderá consultar todas as Odetes na plataforma.</div>
                      <p>{userOauth ? 'autenticando...' : 'você está autenticado!'}</p>
                      <button onClick={finalizarCadastro} className="btn btn-lg btn-outline-success">
                        vamos lá! <ArrowForwardIcon />
                      </button>
                    </div>
                  }

            </DialogContent>
        </Dialog>
      </>
  );
}

const mapStateToProps = (state) => ({});

export default memo(connect(mapStateToProps, actions)(ModalLogin));