//import App from 'next/app';
import 'regenerator-runtime/runtime'
//import { Provider } from 'react-redux';
import { wrapper } from '../redux';
import Layout from '../components/layout';
//import initialize from '../utils/initialize';
import * as Sentry from '@sentry/browser';
import '../styles/style.scss';
//import '../styles/globals.css';
//import { appWithTranslation } from 'next-i18next'
//import {NextUIProvider} from '@nextui-org/react'
Sentry.init({
    dsn: 'https://e6155996ef874365bd3ae5aa28b41804@sentry.io/1847083'
});

export function reportWebVitals(metric) {
  //console.log(`Odete.com.br -> Report WebVitals -> ${metric?.name} - ${metric?.value}`)
}

const MyApp = ({ Component, store, pageProps }) => {
  return (
            <Layout>
              <Component {...pageProps} />
            </Layout>
)
}

// class MyApp extends App {
    
//     static async getInitialProps({ Component, ctx }) {
      
//       initialize(ctx);

//       let pageProps = {
//         ...(Component.getInitialProps
//           ? await Component.getInitialProps(ctx)
//           : {})
//       }

//       return {
//         pageProps
//       };
//     }

//     componentDidCatch(error, errorInfo) {
//         Sentry.withScope((scope) => {
//             Object.keys(errorInfo).forEach((key) => {
//                 scope.setExtra(key, errorInfo[key]);
//             });

//             Sentry.captureException(error);
//         });

//         super.componentDidCatch(error, errorInfo);
//     }

//     render() {
//       const { Component, store, pageProps } = this.props;
//       return (
//         <Provider store={store}>
//           <Layout>
//             <Component {...pageProps} />
//           </Layout>
//         </Provider>);
//     }
// }

//const WrappedApp = appWithTranslation(MyApp);
export default wrapper.withRedux(MyApp);