// resource for handling cookies taken from here:
// https://github.com/carlos-peru/next-with-api/blob/master/lib/session.js

import cookie from 'js-cookie';

export const setCookie = async (key, value) => {
  if (process.browser) {

    var d = new Date();
    d.setTime(d.getTime() + (30*24*60*60*1000)); //keep cookie 30 days
    var expires = d.toGMTString();

    localStorage.setItem(key, value);
    cookie.set(key, value, { expires: 7, path:  '/', sameSite: 'None', secure: true});

    return value;
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1
    });
    localStorage.removeItem(key);
  }
};

export const getCookie = (key, req) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

const getCookieFromBrowser = key => {
  var coo = cookie.get(key) || localStorage.getItem(key);
  return coo;
};

const getCookieFromServer = (key, req) => {

  if (!req || req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find(c => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};
