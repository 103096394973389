import { useEffect, useState, memo } from 'react';
import Service from "../services/service";
import ShareButtons from "./shareButtons";
import PhoneButton from "./phoneButton";
import ModalVideoApresentacao from "./modalVideoApresentacao";
import Image from "./image";
import Rating from "./rating";
import swal from '@sweetalert/with-react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';

import { connect } from 'react-redux';
import actions from '../redux/actions';

const PerfilHeader = (props) => {

  const favoritar = async (event) => {
    if(confirm("Quer indicar esta pessoa para seus clientes caso você não esteja disponível para atender?")){
      var response = await Service.post(`${Service.api}/recommendations`, {recommendUserId: profile?._id});

      if(response.success){
        swal('', 'Profissional diarista incluída com sucesso em sua lista de indicações!', 'success');
      }else{
        swal('', response.message, 'error');
      }
    }
  }


  let profile = props.profile;
  let url = `https://odete.com.br/diarista/${profile?._id}`;
  let description = `Profissional diarista ${profile?.nome} ${profile?.sobrenome} está em ${profile?.cidade} - ${profile?.uf}`;
  let keywords = `diarista ${profile?.nome} ${profile?.sobrenome} está em ${profile?.cidade} - ${profile?.uf}, odete diaristas, contrate diaristas, site para diaristas, diaristas no brasil, app para diaristas, plataforma gratuita para diaristas`;
  let image = `${Service.api}/resizeImage?url=${Service.parseURLImage(profile)}&width=200`;
  let title = `Profissional diarista em ${profile?.cidade} - ${profile?.nome} - ${profile?.bairro}`;
  let titleShare = `Oi, estou te recomendando ${profile?.nome}, profissional diarista que faz parte da Odete - A maior plataforma de diarista do Brasil.`;
  let atendimentos = profile?.solicitacoes?.filter(item => item.matchColaborador && item.matchContratante)
  let ultimoAcesso = profile?.ultimoAcesso ? profile?.ultimoAcesso : profile?.createdAt;

  return (
    <>
      {props?.isAuthenticated && props?.user?.type === 'CONTRATADO' && <IconButton className="align-self-baseline position-absolute end-0 top-0 m-2" style={{'zIndex': '1'}} onClick={favoritar}><FavoriteIcon /></IconButton>}
      <div className="container position-relative">
        <div className="row position-relative d-flex align-items-start">
          <div className="col-md-3 text-center">
            <Image className="img-thumbnail rounded-circle" src={Service.parseURLImage(profile)} alt={profile?.nome} width="160" height="160" />
            <Rating className="d-flex m-0 justify-content-center" value={profile?.rating || 0} readOnly precision={0.1} size="small" />
            {(profile?.videoApresentacao || profile?.youTubeVideoId) && <ModalVideoApresentacao url={profile.youTubeVideoId ? `https://www.youtube.com/embed/${profile.youTubeVideoId}` : profile?.videoApresentacao} />}
          </div>
          <div className="col-md-9 text-center text-md-start">
            <h1 className="h4 mb-2 lh">Contratar diarista {profile?.nome} em {profile?.cidade}/{profile?.uf} - {profile?.bairro}</h1>
            <p className="lh text-muted">{profile?.cidade}/{profile?.uf} - {profile?.bairro}</p>
            
            {(profile?.regioes && profile?.regioes?.length > 0) && <>
                <strong className="barlow text-uppercase">atende nas seguintes regiões:</strong>
                <div className="d-flex gap-1 justify-content-center justify-content-md-start mb-2">{profile?.regioes?.map((item, index) => <small key={`region-${index}`} className="badge bg-danger rounded-pill">{item}</small>)}</div>
              </>
              }

            <small className="d-block m-0 lh">Último acesso: {Service.formatDate(ultimoAcesso, 'dd/MM/yyyy HH:mm:ss')}</small>
            <small className="mb-2 d-block lh">Na Odete desde: {Service.formatDate(profile?.createdAt, 'dd/MM/yyyy')}</small>
            {profile?.fullSchedule && <div className="mb-2 d-flex justify-content-center justify-content-md-start"><small className="badge bg-warning rounded-pill align-items-center barlow">AGENDA LOTADA</small></div>}

            <div className="table-responsive mb-2">
              <ul className="list-unstyled d-flex gap-2 barlow text-center m-0">
                {/*profile.whatsapp && <li className="w-100 border rounded-3 bg-white p-3 d-block"><small className="d-block">enviou</small> <strong className="text-nowrap">{profile?.whatsapp?.counter}</strong><small className="d-block text-nowrap">mensagens no grupo</small></li>*/}
                {profile.totalScore > 0 && <li className="w-100 border rounded-3 bg-white p-3 d-block"><small className="d-block">conquistou</small> <strong className="text-nowrap">{profile.totalScore}</strong><small className="d-block text-nowrap">{profile.totalScore > 1 ? 'pontos' : 'ponto'}</small></li>}
                {profile.totalPropostasVisualizadas > 0 && <li className="w-100 border rounded-3 bg-white p-3 d-block"><small className="d-block">acessou</small> <strong className="text-nowrap">{profile.totalPropostasVisualizadas}</strong><small className="d-block text-nowrap">ofertas</small></li>}
                {profile.totalMatchs > 0 && <li className="w-100 border rounded-3 bg-white p-3 d-block"><small className="d-block">realizou</small> <strong className="text-nowrap">{profile.totalMatchs}</strong><small className="d-block text-nowrap">{profile.totalMatchs > 1 ? `trabalhos` : 'trabalho'}</small></li>}
                {profile.porcentagemContratacao > 0 && <li className="w-100 border rounded-3 bg-white p-3 d-block"><small className="d-block">aceita</small> <strong className="text-nowrap">{profile.porcentagemContratacao}%</strong><small className="d-block text-nowrap">das ofertas</small></li>}
                {profile.tempoMedioVisualizacaoOferta > 0 && <li className="w-100 border rounded-3 bg-white p-3 d-block"><small className="d-block">em até</small> <strong className="text-nowrap">{Service.converterMilissegundos(profile.tempoMedioVisualizacaoOferta)}</strong><small className="d-block text-nowrap">acessa as ofertas</small></li>}
              </ul>
            </div>

            <div className="mb-2 d-md-flex gap-1 justify-content-center justify-content-md-start table-responsive">
              {profile?.servicos?.map(item => {
                return (<small key={item} className="badge rounded-pill bg-danger">{item.toLowerCase()}</small>)
              })}
            </div>
          </div>
          <div className="col-md-12 text-center mb-4 selos d-flex text-center align-items-center justify-content-center">
            {
              profile?.selos && profile?.selos.map((item) => {
                return (<Image key={item._id} src={item?.selo?.imagem} alt={item?.selo?.description} onClick={() => swal({content: <div><p className="text-center">{item?.selo?.description}</p><h3 className="barlow fw-bold">Odete.com.br</h3></div>, icon: `${item?.selo?.imagem}`})} width="60" height="60" className="img-thumbnail rounded-circle c-pointer" />)
              })
            }
          </div>


          

          <div className="text-center col mb-4">
            <ShareButtons url={url} imageUrl={image} title={titleShare} header={`Apoie ${profile?.nome} a chegar mais longe - Compartilhe o perfil com outras pessoas`} showCopy={false} />
          </div>

          <div className="col-12 mb-3 text-center">
            <h2 className="m-0 h5">Quero contratar diarista {profile?.nome}</h2>
          </div>
          <div className="col-12">
            <PhoneButton model={props?.profile} className="btn-block btn-lg w-100 mb-2" />
          </div>
        </div>
      </div>
    </>
  )
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token,
    user: state.authentication.user
  }
);

export default memo(connect(mapStateToProps, actions)(PerfilHeader));