import { useEffect, useState, memo } from 'react';

import Service from "../services/service";
import Image from "./image";

import ShareIcon from '@material-ui/icons/Share';

const PerfilComentarios = (props) => {

  let profile = props.profile;

  return (

              <div className="col-12 mb-3 px-4">
                <h3 className="display-4 mb-4" id="recomendacoes">quem me recomenda...</h3>
                {(!profile?.comentarios || profile?.comentarios?.length === 0) && <p className="text-danger">Ops!... ninguém me recomendou ainda :(</p>}
                {
                  profile?.comentarios && profile?.comentarios?.map(item => {
                    return (<div key={item._id} className="mb-4 border-bottom pb-4">
                      <div className="d-flex align-items-start">
                        <Image src={Service.parseURLImage(item.contratante)} height="40" width="40" alt={item?.contratante?.nome} classNameFigure="me-2" className="rounded-circle me-2 image-perfil" /> 
                        <div className="flex-fill position-relative">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center gap-2">
                              <span className="lh fw-bold m-0 barlow">{item?.contratante?.nome?.toUpperCase()}</span>
                              <small className="text-muted">
                                  {Service.formatDate(item.createdAt, 'dd/MM/yyyy')}
                              </small>
                            </div>
                            <a className="" href={`https://api.odete.com.br/shareComment/${item._id}`} target="_blank">
                              <ShareIcon fontSize="small" titleAccess="Compartilhar" />
                            </a>
                          </div>
                          <p className="text-dark text-break-spaces m-0">{item.mensagem}</p>
                        </div>
                      </div>
                    </div>)
                  })
                }
              </div>


  )
};

export default memo(PerfilComentarios);