import React, { useState, useEffect } from 'react'
import {io} from 'socket.io-client'

export default function useSocket(url) {
   const [socket, setSocket] = useState(null)

   useEffect(() => {

      const socketIo = io(url, {'forceNew': true, transports: ['websocket']})

      socketIo.on("connect", () => {
        //console.log('conectado no socket', socketIo.id); // x8WIv7-mJelg7on_ALbx
      });

      socketIo.on("disconnect", () => {
        //console.log('disconectado no socket', socketIo.id); // undefined
      });

      setSocket(socketIo)

      function cleanup() {
         socketIo.disconnect()
      }
      return cleanup

      // should only run once and not on every re-render,
      // so pass an empty array
   }, [])

   return socket
}