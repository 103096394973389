import React, { useEffect, useState } from "react";
import PubSub from 'pubsub-js'

import Service from "../services/service";
import { logEvent } from '../utils/analytics';

import { connect } from 'react-redux';
import actions from '../redux/actions';

import LockIcon from '@material-ui/icons/Lock';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Drawer from '@material-ui/core/Drawer';

import InputMask from 'react-input-mask';
import Router from "next/router";
import swal from '@sweetalert/with-react';

const phoneButton = (props) => {

  const [submited, setSubmited] = useState(false);
  const [pedidoTelefone, setPedidoTelefone] = useState(false);
  const [noContactSend, setNoContactSend] = useState(false);
  const [telefones, setTelefones] = useState(null);
  const [model, setModel] = useState({...props.model});
  const [anchorEl, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickMenu = (tel) => {
    setAnchor(document.getElementById(`menu-${tel.telefone}`))
  }

  const toggleCotacao = (model) => {

    let colaboradores = [];
    let data = props?.disparos || [];

    if(data && data?.map(item => item._id).includes(model._id)){
      colaboradores = data.filter(item => item._id !== model._id);
      model.checkedCotacao = false;
    }else{
      colaboradores = [...data, model];
      model.checkedCotacao = true;
    }

    props.publish(colaboradores);
  }

  const getTelefone = async (model) => {

    const id = props.model._id; // id da diarista
    const isAuthenticated = props.isAuthenticated;

    logEvent('PERFIL', 'GET TELEFONE');

    /* abre a janela de modal */
    if(!isAuthenticated){
      PubSub.publish('openModal', model);
      setPedidoTelefone(true);
      return false;
    }

    /* abre a janela de confirmar telefone */
    if(isAuthenticated && props?.user && !props?.user?.userTokenVerify){
      PubSub.publish('openModalTelefone', model);
      setPedidoTelefone(true);
      return false;
    }

    if(props?.user?._id === id){
      swal('', 'Ops, você não pode pedir pra visualizar seu próprio telefone :)', 'error');
      return false;
    }

    setPedidoTelefone(true);
  }

  const actionCard = async (action, model, tel) => {

    if(action === 'ligar'){
      let user = `${props.user.nome} - ${props.user.email}`;
      logEvent('PERFIL', `${user} - clicou para ligar para: ${model.nome}`);
      window.open(`tel:${tel.telefone}`, '_blank');
      //location.href = `tel:${tel.telefone}`;
    }else if(action === 'enviarWhatsApp'){
      let user = `${props.user.nome} - ${props.user.email}`;
      logEvent('PERFIL', `${user} - clicou para enviar whatspps para: ${model.nome}`);
      //location.href = Service.renderLinkTelefone(tel, model);
      window.open(Service.renderLinkTelefone(tel, model, props.user), '_blank');
    }else if(action === 'chat') {
      let user = `${props.user.nome} - ${props.user.email}`;
      logEvent('PERFIL', `${user} - clicou para entrar no chat com : ${model.nome}`);
      Router.push('/chats');
    }else if(action === 'recordVoice'){
      let user = `${props.user.nome} - ${props.user.email}`;
      logEvent('PERFIL', `${user} - clicou para enviar um audio para : ${model.nome}`);
      let numero = prompt('Qual o número de seu telefone com DDD?');
      if(numero){
        numero = numero.split('').join(" ");
        let message = prompt('Digite a sua mensagem que sera traduzida para audio', `Oi, meu nome é ${props.user.nome}, acessei o site Odete.com.br e gostaria de saber mais sobre seu serviço de diarista. Meu número de telefone com DDD é: ${numero}`);
        let data  = await Service.tts({
          message,
          numero: tel.telefone
        }).then(resp => {
          alert('sua mensagem foi enviada com sucesso');
        });
        console.log(data)
      }else{
        alert('acao nao realizada');
      }
    }else if(action === 'notContact'){

      var motivo = prompt('Qual foi o motivo?\n\nNos diga, o que aconteceu de errado?');

      if(motivo){
        setNoContactSend(true);

        //abre o modal com o model da diarista
        //pra gente receber o aviso pra retirar do sistema
        let user = `${props.user.nome} - ${props.user.email}`;
        logEvent('NOT-CONTACT', `${user} - nao conseguiu contato com: ${model.nome}`);

        Service.notContact(props.user, model, motivo).then(resp => {
          swal(`Obrigado ${props.user.nome}`, 'Recebemos o seu feedback e iremos entrar em contato com essa diarista', 'success');
        }).finally(() => {
          setNoContactSend(false);
        });
      }

    }
    else{
      alert('acao nao realizada' + action);
    }
  }

  useEffect(() => {

      if(pedidoTelefone && props.isAuthenticated){

        const id = props.model._id; // id da diarista

        setSubmited(true);
        Service.postTelefone({id}).then(resp => {

          if(!resp.success){

            if(resp?.code === 'userTokenVerify'){
              PubSub.publish('openModalTelefone', props.model);
              return false;
            }

            if(resp?.code === 'pacotePremium'){
              PubSub.publish('openFormPayment', props.model);
              return false;
            }

            return false;
          }

          var confetti = document.getElementsByName(`toogle-heart-${id}`);
          confetti.forEach(item => {
            item.checked = true;
          })
          
          //document.getElementsByName(`toogle-heart-${id}`)[0].checked = true;

          setTimeout(() => {

            // if(!props?.model?.checkedCotacao){
            //   toggleCotacao(props?.model);
            // }

            confetti.forEach(item => {
              item.checked = false;
            })

            let newModel = {...props?.model, checkedCotacao: true, telefones: resp?.telefones, next: parseInt(resp?.next, 10)};
            setModel(newModel);

            setTimeout(() => {
              handleClickMenu(resp?.telefones[0]);  
            }, 500)

            
          }, 1800);

        })
        .catch(e => {
          console.log(e);
          alert('Ixi...Ocorreu um erro');
        })
        .finally(() => {
          setPedidoTelefone(false);
          setSubmited(false);
        })
      }

    return () => {} 

  }, [pedidoTelefone, props.isAuthenticated])

  return (
    <>
      {
        (!telefones && !model.telefones) &&
          (<button disabled={submited || model.telefones} type="button" onClick={() => getTelefone(model)} className={`btn ${!Service.verificaPagamento(model) ? 'btn-primary' : 'btn-outline-primary'} rounded-pill ${props.className} barlow fw-bold text-uppercase w-100`} title="Visualizar Telefone">
            {submited ? "consultando" : 'VER TELEFONE'}
          </button>)
      }


      { model.telefones && <div className="list-groups mb-3 w-100">            
        {
            model.telefones && model.telefones.map(tel => (
                <React.Fragment key={tel.telefone}>
                  <div className="input-group mb-1">
                    <InputMask 
                      className="form-control py-2 rounded-pill me-1 pe-5 border-primary"
                      name="cep"
                      mask="(99) 99999-9999"
                      value={tel.telefone}
                      readOnly={true}
                      type="tel"
                    />
                    <button className="btn btn-primary rounded-pill ms-n5" id={`menu-${tel.telefone}`} aria-label="pesquisar" aria-controls={`menu-${tel.telefone}`} aria-haspopup="true" onClick={() => handleClickMenu(tel)}>
                      <MoreVertIcon fontSize="small" titleAccess="abrir opções" />
                    </button>
                    <Drawer anchor='bottom' open={Boolean(anchorEl?.id === `menu-${tel.telefone}`)} onClose={() => setAnchor(null)}>
                      <div className="p-2">
                        <button className="text-uppercase fw-bold barlow mb-2 btn btn-block w-100 rounded-pill btn-primary btn-lg" onClick={() => actionCard('ligar', model, tel) }>
                          realizar ligação
                        </button>
                        
                        {
                            tel.whatsapp && <button className="text-uppercase fw-bold barlow mb-2 btn btn-block w-100 rounded-pill btn-success btn-lg" onClick={() => actionCard('enviarWhatsApp', model, tel, props.user) }>
                              enviar whatsapp
                          </button>
                        }
                        <button className="text-uppercase fw-bold barlow btn btn-block w-100 rounded-pill btn-dark btn-lg" onClick={() => actionCard('notContact', model, tel) } disabled={noContactSend}>
                          {!noContactSend ? `falhou o contato` : `enviando aviso...`}
                        </button>

                      </div>
                    </Drawer>
                  </div>
                </React.Fragment>
              ))
        }
        </div>
      }

      {
        ((!props.user || props?.user?.type === 'CONTRATANTE') && !props.disabledPublishOferta) &&
          <small className={`form-chk-cotacao d-flex mb-2 justify-content-center w-100 text-muted`}>
            <div className="d-flex justify-content-center gap-1 mb-0 align-items-center">
              <input value={props?.model?._id} className="form-check-input m-0" type="checkbox" id={`checkedCotacao-${props?.model?._id}`} onChange={() => toggleCotacao(props?.model)} checked={props?.disparos?.map(item => item._id).includes(props?.model?._id)} />
              <label className="form-check-label text-uppercase d-flex align-items-center lh" htmlFor={`checkedCotacao-${props?.model?._id}`}>publicar oferta</label>
            </div>
          </small>
      }

      <input className="toogle-heart" id={`toogle-heart-${props?.model?._id}`} name={`toogle-heart-${props?.model?._id}`} type="checkbox"/>
      <label className="toogle-heart-label" htmlFor={`toogle-heart-${props?.model?._id}`} aria-label="like">❤</label>

    </>
  );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token,
    user: state.authentication.user,
    colaboradores: state?.authentication?.colaboradores,
    disparos: state?.authentication?.disparos
  }
);

export default connect(mapStateToProps, actions)(phoneButton);