import React, { useState, memo, useCallback, useEffect } from "react";
import swal from '@sweetalert/with-react';
import PubSub from 'pubsub-js';

import ShareButtons from "./shareButtons";
import Rating from "./rating";
import PerfilHeader from "./perfilHeader";
import PerfilSolicitacoes from "./perfilSolicitacoes";
import PerfilComentarios from "./perfilComentarios";
import Service from "../services/service";
import Image from "./image";
import PhoneButton from "./phoneButton";

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Dialog from "@material-ui/core/Dialog";

import { connect } from 'react-redux';
import actions from '../redux/actions';


const Index = (props) => {

  useEffect(() => {

    let isMounted = true;  
    var subscribeModal = PubSub.subscribe('openModalPerfil', (type, model) => {
      openModalDiarista(model)
    });

    return () => {
      isMounted = false;
      PubSub.unsubscribe(subscribeModal);
    }  
  }, []);

  const [model, setModel] = useState(props.model);
  const [open, setModal] = useState(false);
  const [url, setUrl] = useState("");

  const openModalDiarista = (model) => {

    PubSub.publish('backdrop', true);
    const url = new URL(window.location)
    setUrl(url);


    Service.getColaborador(model._id).then(resp => {
      window.history.pushState(null, "sd", `/diarista/${model._id}`);
      setModel({...model, ...resp});
      handleToggle('showPerfil', true);
    }).finally(() => {
      PubSub.publish('backdrop', false);
    });
  }

  const handleToggle = (name, flag) => {
    setHandleToogles((toogles) => ({...toogles, [name]: flag}));
  };

  const [toogles, setHandleToogles] = useState({    
    showPerfil: false
  });

  const closePerfil = () => {
    handleToggle('showPerfil', false);
    window.history.pushState(null, "sd", url);
    //PubSub.unsubscribe(subscribeModal);
  }

  const popStateListener = useCallback(() => {
    closePerfil();
  }, []);
  
  // eslint-disable-next-line
  React.useEffect(() => {
    window.addEventListener("popstate", popStateListener);

    return () => {
      window.removeEventListener("popstate", popStateListener);
    };
  }, [popStateListener]);

  return (
    <>
      {
        toogles.showPerfil && 
          <Dialog open={toogles.showPerfil} hideBackdrop={true} fullScreen={true} className={`modal-profile h-sm-100 overflow-sm-hidden ${props?.disparos?.length > 0 && 'viewBtnDisparo'}`}>
            <div className="p-0 position-relative h-sm-100">
              
              <IconButton color="primary" aria-label="close" onClick={closePerfil} className="position-fixed top-0 start-0 m-2" style={{'zIndex': '1'}}>
                <ArrowBackIcon titleAccess="fechar modal" />
              </IconButton>

              <div className="container-fluid p-0 h-100">
                <div className="row g-0 h-100 overflow-sm-hidden">
                  <div className="col-12 col-md-6 bg-light border-only-desktop">
                    <div className="p-2 p-sm-5 mb-4 jumbotron bg-light jumbotron-diarista position-relative" id="conteudo">
                      <PerfilHeader profile={model} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 h-sm-100 d-flex flex-column">
                    <div className="px-4 py-3 sticky-top border-bottom bg-white">

                      <IconButton color="primary" aria-label="close" onClick={closePerfil} className="position-absolute top-0 end-0 m-2" style={{'zIndex': '1'}}>
                        <CloseIcon titleAccess="fechar modal" />
                      </IconButton>

                      <h3 className="display-4 lh-1">sobre mim...</h3>
                      <p className="text-muted m-0 multi-line-truncate">{model?.historico}</p>
                      <div className="col-12 d-block d-sm-none mt-4">
                        <PhoneButton model={model} className="btn-block btn-lg w-100 mb-2" />
                      </div>

                    </div>
                    <div className="flex-fill overflow-auto">
                      {model?.solicitacoes && model?.solicitacoes?.length > 0 && <PerfilSolicitacoes profile={model} />}
                      {model?.comentarios && model?.comentarios?.length > 0 && <PerfilComentarios profile={model} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
      }
    </>
  );
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token,
    disparos: state?.authentication?.disparos || []
  }
);

export default memo(connect(mapStateToProps, actions)(Index));