import { useEffect, useState, memo } from 'react';
import Service from "../services/service";
import SignInScreen from "./signinscreen";
import PubSub from 'pubsub-js'
import firebase from "../src/firebase/fire.js";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InputMask from 'react-input-mask';
import useSocket from "../hooks/useSocket";

import swal from '@sweetalert/with-react';
import { useForm, Controller, useFormContext, useController  } from "react-hook-form";

import Drawer from '@material-ui/core/Drawer';
import Slide from '@material-ui/core/Slide';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import StarsIcon from '@material-ui/icons/Stars';

import { connect } from 'react-redux';
import actions from '../redux/actions';
import initialize from '../utils/initialize';

/*****************************************************************************************/
/* Esse componente é pra diarista manter o pagamento dela ativo na plataforma via PIX    */
/*****************************************************************************************/
const Index = ({isAuthenticated, authenticateByUID, deauthenticate, user, getUser}) => {

  const [pix, setPix] = useState({});
  const { control, register, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm();
  const [toogles, setHandleToogles] = useState({
    fazerparte: false,
    step: 'information',
    copyText: null,
    isValid: false,
    atendimentos: 0
  });
  const [model, setModel] = useState(false);

  const handleToggle = (name, flag) => {
    setHandleToogles((toogles) => ({...toogles, [name]: flag}));
  };

  const subscribeModal = PubSub.subscribe('openModalConfirmationPix', (type, model) => {

    console.log('openModalConfirmationPix', model);

    if(model){
      setModel(model);
    }

    handleToggle('fazerparte', true);
    
  });

  const copyClipboardConfirmationSaldo = async (text) => {
    await Service.writeText(text);
    handleToggle('copyText', text);
  }

  const handleUpdatePix = (transaction) => {
    var email = getValues("email");

    if(transaction.solicitacaoPagador === email && transaction.status === 'CONCLUIDA'){
        getUser().then((resp) => {
          swal({content: <div><p className="text-center">Obrigada, o seu PIX foi aceito!</p><h3 className="barlow fw-bold">Na Odete você pode MUITO mais!</h3></div>, icon: 'success'}).then(() => {
            handleToggle('isValid', true);
            handleToggle('fazerparte', false);
            Service.scrollIntoView('__next');
          });
        });
    }
  }

  const handleSubmitPix = (data) => {

    data.cpf = data.cpf.toString().replace(/\D/g, "");

    if(!Service.validCpfCnpj(data.cpf)){
      swal('', 'cpf inválido', 'error');
      return false;
    }

    data.userId = user?.id;
    data.type = user?.type;
    data.amount = (parseFloat(data.amount) + toogles.atendimentos).toString()
    data.atendimentos = toogles.atendimentos;

    PubSub.publish('backdrop', true);

    Service.post(`${Service.api}/integrations/gerencianet/pixCreateCharge`, data)
    .then(resp => {

      setPix(resp);
      handleToggle('step', 'payment');
    }).finally(() => {
      PubSub.publish('backdrop', false);
    });
  }

  const handleClose = () => {
    setPix({});
    handleToggle('step', 'information');
    handleToggle('fazerparte', false);
  }

  const socket = useSocket(Service.api);

  useEffect(() => {
    if(socket){
      socket.on("newPIX", handleUpdatePix);
    }

    return ()=> {
      if(socket){
        socket.off("newPIX");
      }
    }
  }, [socket]);

  useEffect(() => {
    if(user){
      setValue('nome', `${user?.nome} ${user?.sobrenome}`);
      setValue('cpf', user?.cpf);
      setValue('email', user?.email);
      setValue('amount', "59");
      setValue('telefone', user?.telefoneValidado?.replace("55", ""))


      //agora o usuário existe!!!
      //console.log('user', user);
      getPlans();

    }
  }, [user]);

  useEffect(() => {

    let isMounted = true;  

    return () => {
      isMounted = false;
      PubSub.unsubscribe(subscribeModal);
    }  
  }, []);

  const [plans, setPlans] = useState([]);

  const getPlans = async () => {
    //handleToggle('fazerparte', true);

    var getValue = await Service.get(`${Service.api}/colaborador/${user?.id}`);
    handleToggle('atendimentos', getValue.totalMatchs)

    var planos = await Service.get(`${Service.api}/plans`);
    setPlans(planos);

  }

  return (
      <Drawer anchor='bottom' open={toogles.fazerparte} onClose={handleClose} data-component="confirmationRegisterPix" classes={{ paperAnchorBottom: 'h-100 overflow-hidden'}}>
        <div className="position-relative h-100 overflow-hidden d-flex flex-column h-100 overflow-hidden">
          {!model?.disabledIcon && <IconButton aria-label="close" onClick={handleClose} className="position-absolute position-absolute top-0 end-0 m-2">
            <CloseIcon titleAccess="fechar modal" />
          </IconButton>}

          <Slide direction="left" in={toogles.step === 'information'} mountOnEnter unmountOnExit>
            <form onSubmit={handleSubmit(handleSubmitPix)} className="h-100 border-3 overflow-hidden d-flex flex-column">
              <div className="text-center p-4 ">
                <img src={Service.parseURLImage(user)} alt="manter cadastro ativo" width="64" className="mb-2 rounded-circle" />
                <h6 className="barlow fw-bold m-0">Manter meu cadastro ativo na maior plataforma de diaristas profissionais do Brasil!</h6>
                <p>Quero ter acesso a funcionalidades exclusivas dentro da plataforma.</p>
              </div>
              <div className="container-fluid h-100 overflow-auto flex-fill">
                <div className="row">
                  <div className="form-group mb-3 col-6">
                    <label htmlFor="nome" className="form-label barlow fw-bold">Nome</label>
                    <input placeholder="Ex: Ana Maria de Paula" autoFocus type="text" id="nome" className="form-control" required={true} name="nome" {...register("nome")} />
                  </div>
                  <div className="form-group mb-3 col-6">
                    <label htmlFor="cpf" className="form-label barlow fw-bold">CPF</label>
                    <InputMask
                      type="tel"
                      id="cpf"
                      mask="999.999.999-99"
                      placeholder="XXX.XXX.XXX-XX"
                      name="cpf"
                      required={true}
                      className="form-control"
                      {...register("cpf")}
                    />
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label htmlFor="telefone" className="form-label barlow fw-bold">Telefone</label>
                    <InputMask
                      type="tel"
                      mask="(99) 99999-9999"
                      placeholder="(99) 99999-9999"
                      name="telefone"
                      required={true}
                      id="telefone"
                      className="form-control"
                      {...register("telefone")}
                    />
                  </div>

                  <div className="form-group mb-3 col-12">
                    <label htmlFor="email" className="form-label barlow fw-bold">E-mail</label>
                    <input placeholder="Ex: maria@gmail.com" id="email" type="email" className="form-control" required={true} name="email" {...register("email")} />
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="amount" className="form-label barlow fw-bold">Qual plano você deseja?</label>
                  </div>

                  <div className="d-flex justify-content-between gap-3">

                    {
                      plans && plans.filter(item => item.active).sort((a,b) => a?.price-b?.price).map(item => {
                        return (
                          <label htmlFor={item._id} key={item._id} className={`position-relative py-3 text-center w-100 btn btn-outline-primary shadow-sm btn-sm ${watch('amount') == (item.price) ? 'active' : ''}`}>
                            {item.destaque && <StarsIcon fontSize="small" className="position-absolute top-0 end-0 bg-white rounded-circle translate-middle text-warning" />}
                            {item.principal && <small className="badge rounded-pill px-3 bg-success position-absolute top-0 start-50 translate-middle">mais usado</small>}
                            <input type="radio" value={item.price} {...register("amount")} required={true} id={item._id} name="amount" />
                            <span className="barlow fw-bold text-uppercase d-block">{Service.formatCurrency(item.price + toogles.atendimentos)}<br/>{item.name}</span>
                            <small className="d-block barlow">{item.periodMonths} {item.periodMonths > 1 ? 'meses' : 'mês'}</small>
                            {toogles.atendimentos > 0 && <small>Você tem {toogles.atendimentos} atendimentos pela Odete</small>}
                          </label>
                        )
                      })
                    }
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="confirmation" required {...register("confirmation")} />
                    <label className="form-check-label" htmlFor="confirmation">
                      entendi e aceito os termos e condições
                    </label>
                  </div>
                </div>
              </div>
              <div className="p-2 border-top border-1">
                <button type="submit" className="w-100 barlow fw-bold text-uppercase btn btn-lg btn-danger">Pagar o Pix de {Service.formatCurrency( parseFloat(watch("amount")) + parseFloat(toogles.atendimentos))}</button>
              </div>
            </form>
          </Slide>

          <Slide direction="left" in={toogles.step === 'payment'} mountOnEnter unmountOnExit>
            <div className="text-center h-100 d-flex flex-column">
              <div className="flex-fill overflow-auto p-4">
                <VerifiedUserIcon className="text-success" fontSize="large" />
                <p>É tudo instantâneo, rápido e seguro.</p>
                <p className="fw-bold barlow text-uppercase m-0">Abra o app do banco, selecione pix qr-code e aponte a câmera do celular aqui!</p>
                <img alt="gerando qr-code pix" src={pix?.generate?.imagemQrcode} />
                <p>ou</p>
                <p className="fw-bold barlow text-uppercase">PIX Copia e Cola</p>
                <pre>{pix?.generate?.qrcode}</pre>
              </div>
              <div className="p-2">
                <button type="button" onClick={() => copyClipboardConfirmationSaldo(pix?.generate?.qrcode)} className={`w-100 btn btn-block ${toogles.copyText ? 'btn-success' : 'btn-outline-success'} text-uppercase`}>
                  {!toogles.copyText && <><CheckCircleOutlineIcon /> <small className="d-block">copiar pix copia e cola</small></>}
                  {toogles.copyText && <><CheckCircleIcon /> <small className="d-block">copiado</small></>}
                </button>
              </div>
            </div>
          </Slide>
        </div>
      </Drawer>
  );
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token,
    user: state.authentication.user
  }
);

export default memo(connect(mapStateToProps, actions)(Index));