/*********************************************/
/* esse arquivo eh inicializado no components/layout.js */
/*********************************************/
import ReactGA from 'react-ga'
export const initGA = () => {
  if(process.env.NODE_ENV !== 'development'){
    ReactGA.initialize('UA-88189736-1')
  }
}
export const logPageView = () => {
  if(process.env.NODE_ENV !== 'development'){
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }
}
export const logEvent = (category = '', action = '') => {
  if(process.env.NODE_ENV !== 'development'){
    if (category && action) {
      ReactGA.event({ category, action })
    }    
  }
}
export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal })
  }
}