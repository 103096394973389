import React from "react";
import firebase from "../src/firebase/fire.js";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import Service from "../services/service";
import { setCookie, removeCookie } from '../utils/cookie';
import PubSub from 'pubsub-js'
import { connect } from 'react-redux';
import actions from '../redux/actions';
import initialize from '../utils/initialize';

class SignInScreen extends React.Component {
  
  state = {
    isSignedIn: false,
    isError: false,
    errorMessage: ''
  };

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        clientId: '915148093385-sa70h1c2qb3rblpm93kniutjmab8m1so.apps.googleusercontent.com',
        fullLabel: 'Login com Google'
      },
      {
        provider: firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        fullLabel: 'Login com Twitter'
      },
      {
        provider: firebase.auth.GithubAuthProvider.PROVIDER_ID,
        fullLabel: 'Login com Github'
      },
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        fullLabel: 'Login com Facebook'
      },
      // {
      //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //   fullLabel: 'Login com e-mail'
      // },
      {
        provider: 'apple.com',
        fullLabel: 'Login com Apple'
      },
      {
        provider: 'microsoft.com',
        fullLabel: 'Login com Microsoft'
      },
      {
        provider: 'yahoo.com',
        fullLabel: 'Login com Yahoo'
      }
    ],
    credentialHelper: 'none', //or googleyolo automatic login
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        return false;
      },
      signInFailure: (error) => {
        console.log('falhou o signInFailure in SignInScreen')
        return error;
      }
    }
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {

    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
      console.log('this.unregisterAuthObserver', user);

      // if(user){
      // console.log('chamou o authenticateByUID => SignInScreen => onAuthStateChanged => ', user);
      //   await this.props.authenticateByUID(user.uid, user)
      // }
      

      this.setState({
        isSignedIn: !!user
      })      
    });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    return (<>

        {
          (!this.props.isAuthenticated) && 
            <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
        }
        {
          (this.props.isAuthenticated && this.state.isSignedIn) && 
            <div className="text-center">
              <button className="btn btn-danger btn-sm" onClick={() => firebase.auth().signOut()} >
                {firebase.auth().currentUser.displayName} - Sair
              </button>
            </div>
        }
      </>)
  }
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token
  }
);

export default connect(mapStateToProps, actions)(SignInScreen);