import { memo, useState, useEffect } from 'react';

const Iframe = (props) => {

  const id = props.id || new Date().getTime();
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {

    const intersectionObserver = new IntersectionObserver(entries => {

      if (entries.some(entry => entry.isIntersecting)) {
        setVisible(true);
      }
    })

    var element = document.getElementById(`${id}`);

    intersectionObserver.observe(element);
    return () => intersectionObserver.disconnect();
  }, []);

  return (
        <iframe
          src={isVisible ? props.src : ''}
          className={props.className}
          width={props.width || "100%"}
          preload="none"
          loading="lazy"
          id={id}
          title={props.title}
          height={props.height}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
  )

}

export default memo(Iframe);