import { useState, memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Image from "./image";
import Iframe from "./iframe";
import Icon from '@material-ui/core/Icon';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import YouTubeIcon from '@material-ui/icons/YouTube';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: `45%`,
    [theme.breakpoints.down('md')]: {width: '80%'},
    backgroundColor: theme.palette.background.paper,
    border: '6px solid #fff',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: theme.shadows[5],
  },
}));


const ModalVideo = (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={`position-relative ${props.className}`}>
        <button title="vídeo de apresentação" className="btn text-danger p-0 m-0 lh d-flex align-items-end justify-content-center w-100" onClick={handleOpen}>
          <YouTubeIcon width="24" height="24" className="lh" />
        </button>
      </div>

      <Modal
        aria-labelledby={props.altImage}
        aria-describedby={props.altImage}
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={modalStyle}>
            <div className="ratio ratio-1x1">
              <Iframe title="" width="300" height="300" src={`${props.url}`} />
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default memo(ModalVideo);