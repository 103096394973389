import Service from "../services/service";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { useEffect, useState, memo, useContext } from "react";
import {UserContext} from "../components/userContext";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InputMask from 'react-input-mask';

import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Telefones = (props) => {

  const { state, setState } = useContext(UserContext);

  const [telefones, setTelefones] = useState(state.form.telefones  || []);
  const [operadoras, setOperadoras] = useState([]);
  const [max, setMax] = useState(props.max);

  const verifyPhone = (tel, index) => {

    if(!telefones[index].telefone){
      alert('preencha o campo telefone');
        document.getElementById('telefone-' + index).focus();
        return false;
    }

    var telefone = {
      ...telefones[index],
      validar: ``,
      verify: false,
      sendValidade: true
    };

    telefones[index] = telefone;

    let form = {...state.form, telefones}

    setState({
      ...state,
      form
    });

    Service.verifyPhone(tel).then(resp => {
        document.getElementById('validar-' + index).focus();
    })
  }

  const validarMesmoAssim = (tel, index) => {

     var telefone = {
        ...telefones[index],
        validar: ``,
        verify: true,
        sendValidade: false
      };

      telefones[index] = telefone;

      let form = {...state.form, telefones}

      setState({
        ...state,
        form
      });
    }

  const validar = (tel, index) => {

   var telefone = {
      ...telefones[index],
      verify: (tel.token === tel.validar ? true : false),
      sendValidade: false
    };

    telefones[index] = telefone;

    let form = {...state.form, telefones}

    setState({
      ...state,
      form
    });

    if(tel.token === tel.validar){
      alert('validado')
    }else{
      alert('invalido');
    }
  }

  const add = () => {

    telefones.push({
      operadora: "",
      telefone: "",
      whatsapp: false
    });

    let form = {...state.form, telefones}

    setState({
      ...state,
      form
    });
  }

  const remove = (item) => {
    const idx = telefones.indexOf(item);
    telefones.splice(idx, 1);


    let form = {...state.form, telefones}

    setState({
      ...state,
      form
    });
  }

  useEffect(() => {

    // state.form.telefones.forEach(tel => {
    //   tel.token = Math.floor(1000 + Math.random() * 9000).toString();
    //   tel.verify = false;
    //   tel.validar = '';
    //   tel = tel;
    // });
    // setTelefones(state.form.telefones);

    Service.getOperadoras().then(resp => {
      if(resp && resp?.length){
        setOperadoras(resp);
      }
    }).catch(e => {
      console.log('erro => ', e);
    });
  }, [])

  const handleChange = (event, index) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var telefone = {
      ...telefones[index],
        // verify: (name === `telefone` ? false : telefones[index].verify),
      [name]: value
    };

    telefones[index] = telefone;

    let form = {...state.form, telefones}

    setState({
      ...state,
      form
    });
  }

  useEffect(() => {
    if(state.form.telefones){
      setTelefones(state.form.telefones)
    }
  }, [state])

  return (
    <div>
      <div className="alert alert-warning">
        <strong className="">Cadastre seus telefones!</strong>
        <p className="mb-0">Se você tem mais de 1 telefone (clique em quero adicionar outro telefone) e indique se você tem WHATSPP!</p>
      </div>
      {
        telefones && telefones?.map((tel, i) => (
          <div className="row align-items-start" key={i}>
              
              <div className="col-md-4 ten-step">
                <div className="form-group mb-3">
                    <label className="form-label font-weight-bold" htmlFor={`operadora-${i}`}>Qual sua operadora?</label>
                    <select
                      className="form-control form-select"
                      required={true}
                      name="operadora"
                      id={`operadora-${i}`}
                      value={telefones[i].operadora}
                      onChange={() => handleChange(event, i)}
                    >
                      <option value="">Selecione</option>
                      <optgroup label="Operadora">
                        {operadoras && operadoras?.map(operadora => {
                          return (
                            <option key={operadora.nome} value={operadora.nome}>
                              {operadora.nome}
                            </option>
                          );
                        })}
                      </optgroup>
                    </select>
                </div>
              </div>

              <div className="col-md-4 eleven-step">
                  <div className="form-group mb-3">
                    <label className="form-label font-weight-bold" htmlFor={`telefone-${i}`}>Qual seu telefone?</label>
                    <InputMask
                      type="tel"
                      id={`telefone-${i}`}
                      mask="(99) 99999-9999"
                      placeholder="(99) 99999-9999"
                      name="telefone"
                      required={true}
                      className="form-control"
                      value={telefones[i].telefone}
                      onChange={() => handleChange(event, i)}
                    />                      


                  </div>
                </div>

              {/*
                tel.sendValidade && <div className="col-md-4 eleven-step">
                  <div className="form-group mb-3">
                    <label className="form-label font-weight-bold" htmlFor={`validar-${i}`}>Qual o código para validar?</label>
                    <div className="input-group">
                      <input
                        type="number"
                        required={true}
                        max="99999999999"
                        name="validar"
                        min="0"
                        id={`validar-${i}`}
                        className="form-control"
                        placeholder="XXXX"
                        value={telefones[i].validar}
                        onChange={() => handleChange(event, i)}
                      />
                      <div className="input-group-append">
                          <button type="button" onClick={() => validar(tel, i) } className="btn btn-sm btn-outline-secondary">
                              verificar
                          </button>                    
                      </div>
                    </div>
                    <small className="text-dark">ligando...após a ligação, digite o código recebido.</small> 
                    <button type="button" onClick={() => validarMesmoAssim(tel, i) } className="btn rounded-pill btn-link btn-sm btn-link p-0">não recebi a ligação :(</button>
                  </div>
                </div>
                */}

              <div className="col-md-4">
                <div className="form-group twelve-step">
                  <label className="form-label font-weight-bold" htmlFor={`whatsapp-${i}`}>
                    <WhatsAppIcon fontSize="small" titleAccess="Marque se tiver whatspp no seu telefone" /> Tem Whatspp?
                  </label>
                  { operadoras && operadoras.length > 0 && 
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch
                          checked={telefones[i].whatsapp}
                          value={telefones[i].whatsapp}
                          id={`whatsapp-${i}`}
                          name="whatsapp"
                          onChange={() => handleChange(event, i)}
                        />}
                        label={` Sim, tenho!`}
                      />
                    </FormGroup>}
                </div>

              </div>

              {i !== 0 && 
                <div className="col">
                  <div className="form-group mb-3 d-grid">
                    <button type="button" onClick={() => remove(tel)} className="btn btn-danger btn-block rounded-pill">remover</button>
                  </div>
                </div>
              }
          </div>
        ))
      }

      <div className="row">
        <div className="col d-grid">
          <button disabled={telefones && telefones?.length === max} type="button" onClick={() => add()} className="btn btn-info rounded-pill btn-block">quero adicionar outro telefone</button>
        </div>
      </div>

    </div>
  )
}

export default Telefones