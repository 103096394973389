import { firebase } from '@firebase/app'
//import * as firebase from 'firebase/app';
import 'firebase/messaging'
import 'firebase/auth'

import {
    firebaseConfig
} from "./config.js";

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}
export default firebase;