import React, { memo } from 'react';

const Index = ({value, className}) => {

	let stars = new Array(5).fill();

    let st = stars.map((i, index) => {
    	let icon = (value > index ? "text-warning" : "text-secondary")
    	return <span className={icon} key={index}>★</span>
    })

    return (<div className={`d-flex align-items-center stars stars-${Math.round(value)} ${className}`}>{st}</div>);
}

export default memo(Index);